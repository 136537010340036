// @ts-nocheck
import { BaseTransformer } from '../base-transformer';
import type { IAdapter, InitArguments } from '../event-client';
import type { EventPayloads, EventTypes, LoginPayload } from '../events';
import * as Sentry from '@sentry/vue';

const subscribedEvents: Array<EventTypes> = [
	'session-start',
	'page-view',
	'product-description-click',
	'add-to-cart',
	'remove-from-cart',
	'view-cart',
	'proceed-to-checkout',
	'checkout-page-landing',
	'checkout-payment-method-select',
	'checkout-thankyou',
	'purchase',
	'view-item-list',
	'product-view',
	'product-search',
];

interface TransformerResponse {
	name: string;
	data: Record<string, unknown>;
}

class Transformer {
	static transformProductView(
		data: EventPayloads['product-view'],
	): TransformerResponse {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
		const { meta_data, ...rest } = data;
		return {
			name: 'view_item',
			data: rest,
		};
	}
}

const TransformerMap = {
	'add-to-cart': BaseTransformer.transformAddToCartEvent,
	'remove-from-cart': BaseTransformer.transformRemoveFromCartEvent,
	'product-view': Transformer.transformProductView,
	'proceed-to-checkout': BaseTransformer.transformProceedToCheckoutEvent,
	purchase: BaseTransformer.transformPurchaseEvent,
};

const loadGtag = async (arg: InitArguments['gtag']) => {
	const script = document.createElement('script');
	script.async = true;
	script.src = `https://www.googletagmanager.com/gtag/js?id=${arg.tagId}`;
	document.body.appendChild(script);

	await new Promise((resolve, reject) => {
		script.onload = () => {
			resolve(1);
		};
		script.onerror = (e) => {
			console.log('Failed to load gtag script: script:on-error', e);
			Sentry.captureException('Failed to load gtag script', (scope) => {
				scope.setContext('error', {
					message: e.toString(),
				});
				return scope;
			});
			script.remove();
			reject(1);
		};
	});
};
export class GtagAdapter implements IAdapter {
	name: string = 'gtag';
	queue: Array<{ name: EventTypes; data: EventPayloads[EventTypes] }> = [];

	async init(arg: InitArguments['gtag']): Promise<void> {
		let retry = 1;
		while (retry >= 0) {
			try {
				await loadGtag(arg);
				break;
			} catch (e) {
				console.log('Failed to load gtag script', e);
				retry--;
			}
		}

		window.dataLayer = window.dataLayer || [];
		window.gtag = function () {
			// eslint-disable-next-line prefer-rest-params
			window.dataLayer!.push(arguments);
		};
		window.gtag('js', new Date());
		window.gtag('config', arg.tagId);

		this.queue.forEach((event) => {
			// @ts-ignore
			const { data, name } = TransformerMap[event.name]
				? // @ts-ignore
					TransformerMap[event.name](event.data)
				: { data: event.data, name: event.name };
			window.gtag!('event', name ?? event.name, data);
		});
	}

	sendEvent<T extends EventTypes>(
		eventName: T,
		eventData: EventPayloads[T],
	): void {
		if (!subscribedEvents.includes(eventName)) {
			return;
		}
		if (!window.gtag) {
			this.queue.push({ name: eventName, data: eventData });
			return;
		}
		// @ts-ignore
		const { data, name } = TransformerMap[eventName]
			? // @ts-ignore
				TransformerMap[eventName](eventData)
			: { data: eventData, name: eventName };
		window.gtag!('event', name ?? eventName, data);
	}
	login(data: LoginPayload): void {
		if (!window.gtag) {
			return;
		}
		window.gtag('set', 'user_properties', data);
	}
	logout(): void {
		if (!window.gtag) {
			return;
		}
		window.gtag('set', 'user_properties', {});
	}
}
