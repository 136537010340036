<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import { Primitive, type PrimitiveProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';
import type { ButtonVariants } from '.';
import { buttonVariants } from '.';

interface Props extends PrimitiveProps {
	variant?: ButtonVariants['variant'];
	size?: ButtonVariants['size'];
	class?: HTMLAttributes['class'];
	disabled?: boolean;
	hasError?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	as: 'button',
	variant: 'primary',
	size: 'xs',
	class: '',
});
</script>

<template>
	<Primitive
		:as="props.as"
		:as-child="props.asChild"
		:class="cn(buttonVariants({ variant, size, hasError }), props.class)"
		:disabled="props.disabled"
	>
		<slot name="leftIcon" />
		<slot name="default" />
		<slot name="rightIcon" />
	</Primitive>
</template>
