import { type TObject, type TArray, type TRecord } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';

interface ValidatorFactoryReturn<T> {
	schema: TObject;
	verify: (data: T) => T;
}

const validatorFactory = <T extends unknown>(
	schema: TObject,
): ValidatorFactoryReturn<T> => {
	const C = TypeCompiler.Compile(schema);

	const verify = (data: T): T => {
		const isValid = C.Check(data);
		if (isValid) {
			return data;
		}
		throw new Error(
			JSON.stringify(
				[...C.Errors(data)].map(({ path, message }) => ({ path, message })),
			),
		);
	};

	return { schema, verify };
};

interface ValidatorFactoryReturnArray<T> {
	schema: TArray;
	verify: (data: T) => T;
}
const validatorFactoryArray = <T extends unknown>(
	schema: TArray,
): ValidatorFactoryReturnArray<T> => {
	const C = TypeCompiler.Compile(schema);

	const verify = (data: T): T => {
		const isValid = C.Check(data);
		if (isValid) {
			return data;
		}
		throw new Error(
			JSON.stringify(
				[...C.Errors(data)].map(({ path, message }) => ({ path, message })),
			),
		);
	};

	return { schema, verify };
};

interface ValidatorFactoryReturnRecord<T> {
	schema: TRecord;
	verify: (data: T) => T;
}

const validatorFactoryRecord = <T extends unknown>(
	schema: TRecord,
): ValidatorFactoryReturnRecord<T> => {
	const C = TypeCompiler.Compile(schema);

	const verify = (data: T): T => {
		const isValid = C.Check(data);
		if (isValid) {
			return data;
		}
		throw new Error(
			JSON.stringify(
				[...C.Errors(data)].map(({ path, message }) => ({ path, message })),
			),
		);
	};

	return { schema, verify };
};
export { validatorFactory, validatorFactoryArray, validatorFactoryRecord };
