import { cva, type VariantProps } from 'class-variance-authority';

export const buttonVariants = cva(
	/*tw*/ 'capitalize rounded-medium flex items-center gap-xs justify-center whitespace-nowrap text-sm ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-0 focus-visible:ring-opacity-15 disabled:pointer-events-none font-medium',
	{
		variants: {
			variant: {
				primary:
					/*tw*/ 'bg-gray-800 text-white hover:bg-gray-900 focus:outline disabled:text-gray-400 disabled:bg-gray-100',
				secondary:
					/*tw*/ 'bg-white border-gray-300 border text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-300 disabled:text-gray-500 disabled:[&_svg]:fill-gray-400 disabled:bg-gray-50',
				tertiary:
					/*tw*/ 'bg-white text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 focus-visible:ring-0 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400',
				text: /*tw*/ 'bg-white text-black disabled:text-gray-400 disabled:[&_svg]:fill-gray-400',

				outline:
					/*tw*/ 'bg-transparent  text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 ',
				underline:
					/*tw*/ 'bg-white  text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 focus:text-blue-600 border-b-4 focus:border-blue-600 rounded-none ',
				blue: /*tw*/ 'text-white bg-blue-700 border-blue-800 disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300 disabled:border',
				plain: /*tw*/ 'text-blue-700 disabled:text-gray-400',
				'plain-gray': /*tw*/ 'text-gray-600 disabled:text-gray-400',

				dropdown:
					/*tw*/ 'text-gray-900  rounded-medium border border-solid border-gray-300 justify-between flex',
				highlight:
					/*tw*/ 'bg-white  text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 focus:text-blue-600 border-b-2 border-blue-600 rounded-none p-xl',
				inactivetab:
					/*tw*/ 'bg-white  text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 rounded-none p-xl hover:rounded-small',
				danger: /*tw*/ 'bg-red-600  text-white rounded-sm p-xl',
				activetab:
					/*tw*/ 'bg-gray-100  text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400 focus:bg-gray-100  rounded-small p-xl',
			},
			size: {
				xxs: /*tw*/ 'py-xs px-sm text-xss [&_svg]:h-2xl [&_svg]:w-2xl',
				xs: /*tw*/ 'py-sm px-lg text-sm [&_svg]:h-3xl [&_svg]:w-3xl',
				sm: /*tw*/ ' py-md px-xl text-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				md: /*tw*/ ' py-lg px-2xl text-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				lg: /*tw*/ ' py-lg px-3xl text-md gap-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				xl: /*tw*/ ' py-4xl px-4xl text-md gap-sm [&_svg]:h-5xl [&_svg]:w-5xl',
				'2xl':
					/*tw*/ ' py-3xl px-6xl text-lg gap-lg [&_svg]:h-7xl [&_svg]:w-7xl',
				dropdown: /*tw*/ 'p-lg gap-md text-md font-normal',
			},
			hasError: {
				true: '!border-error-300',
			},
		},
		defaultVariants: {
			variant: 'primary',
			size: 'xs',
		},
	},
);
export type ButtonVariants = VariantProps<typeof buttonVariants>;
export { default as Button } from './Button.vue';
