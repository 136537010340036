import * as Sentry from '@sentry/vue';
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
		console.log('Error VUE: ', error, instance, info);
		Sentry.captureException(error);
	};

	// Also possible
	nuxtApp.hook('vue:error', (error: any) => {
		console.log('Error NUXT: ', error, error.data);
		Sentry.captureException(error, (scope) => {
			scope.setContext('data', error.data);
			return scope;
		});
	});
});
