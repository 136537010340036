// @ts-nocheck
import { type InitArguments, type IAdapter } from '../event-client';
import { type EventPayloads, type EventTypes } from '../events';
import { onLoad } from '../utils';

interface SavyourOrderPlacePayload {
	order_id: string;
	cart_total: number;
	payment_details: {
		credit_card_bin?: string;
		credit_card_company?: string;
	};
	cart_items: {
		category_name: string;
		product_amount: string;
		product_quantity: number;
		product_id: string;
		product_name: string;
	}[];
	is_new_user: boolean;
	gross_amount: number;
}

class Transformer {
	static transformPurchase(data: EventPayloads['purchase']) {
		const payload: SavyourOrderPlacePayload = {
			order_id: data.orderNumber,
			cart_total: data.frontendSubtotal,
			gross_amount: data.totalPrice,
			payment_options: data.paymentType,
			is_new_user: !!data.userId,
			payment_details: {},
			cart_items: data.lineItems.map((i) => {
				return {
					category_name: `${i.node_l1}-${i.node_l2}`,
					product_amount: i.price,
					product_quantity: i.quantity,
					product_id: i.productId,
					product_name: i.productTitle,
				};
			}),
		};

		return payload;
	}
}

const TransformerMap = {
	purchase: Transformer.transformPurchase,
};

export class SavyourAdapter implements IAdapter {
	name = 'savyour';
	subscribedEvents: Array<EventTypes> = ['purchase'];

	async init(args: InitArguments['savyour']): Promise<void> {
		await new Promise<void>((resolve, reject) => {
			onLoad(() => {
				'savyour' in window ||
					((window.savyour = function () {
						// eslint-disable-next-line prefer-rest-params
						window.savyour.q.push(arguments);
					}),
					(window.savyour.q = []));
				const script = document.createElement('script');
				script.src = 'https://affiliate.savyour.com.pk/sapw.min.js';
				script.async = true;
				script.defer = true;
				document.body.appendChild(script);
				script.onload = () => {
					resolve();
				};
				script.onerror = reject;
			});
		});

		window.savyour('init', args.authKey);
	}

	async sendEvent<T extends EventTypes>(name: T, data: EventPayloads[T]) {
		if (!this.subscribedEvents.includes(name)) {
			return;
		}
		const payload = TransformerMap[name as keyof typeof TransformerMap](
			data as any,
		);
		window.savyour('orderPlace', payload);
	}

	login(): void {
		// no-op
		return;
	}

	logout(): void {
		// no-op
		return;
	}
}
