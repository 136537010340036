import { isJSON } from '~/utils/checkout.ts';
import axios from 'axios';
import { useCountryCode } from '~/stores/country';

class _FBConversion {
	apiWorker: Worker | null = null;

	async apiCall(payload: {
		event_name: string;
		content?: object;
		custom_data?: object;
	}) {
		const config = useRuntimeConfig();
		const userUUID = useCookie('user_uuid');
		const countryCode = useCountryCode().countryCode;
		const zipCode = useCookie('zip_code');

		const fbp = useCookie('_fbp');
		const fbc = useCookie('_fbc');

		const user = isJSON(localStorage.getItem('logan')!)
			? JSON.parse(localStorage.getItem('logan')!)
			: null;

		const { event_name, ...restPayload } = payload;
		const data = {
			event_data: {
				event_name: event_name,
				event_id: userUUID.value,
				action_source_url: window.location.href,
			},
			customer_data: {
				first_name: user && user.firstName ? user.firstName : '',
				last_name: user && user.lastName ? user.lastName : '',
				email: user && user.email ? user.email : '',
				country: countryCode ?? null,
				zipCode: zipCode.value ? zipCode.value.toString() : null,
				fbp: fbp.value ?? null,
				fbc: fbc.value ?? null,
			},
			...restPayload,
		};

		try {
			const headers = {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			};
			if (window.Worker) {
				if (!this.apiWorker) {
					this.apiWorker = new Worker('/fb-conversion-thread.js');
					this.apiWorker.onmessage = (e) => {
						console.log('Worker response', e.data);
					};
					this.apiWorker.onerror = (e) => {
						console.log('Worker error', e, e.message);
					};
				}

				this.apiWorker.postMessage({
					url: `${config.public.apiBaseUrl}/server_events/fb_conversions`,
					data: data,
					headers: headers,
				});
			} else {
				const res = await axios.post(
					`${config.public.apiBaseUrl}/server_events/fb_conversions`,
					JSON.stringify(data),
					{
						headers: headers,
					},
				);
				if (res.status === 200) {
					return await res.data;
				} else {
					console.log('Fb conversion error');
					// throw new Error('Fb conversion error');
				}
			}
		} catch (error) {
			console.log('Fb conversion error', error);
			// throw new Error('Fb conversion error');
		}
	}
}

export const FBConversion = new _FBConversion();
