import { defineNuxtPlugin } from 'nuxt/app';
import { CathodeEventClient } from '@laam/cathode-events';
import { isJSON } from '~/utils/checkout';
import { useCountryCode } from '~/stores/country';

export default defineNuxtPlugin(() => {
	const logan = isJSON(localStorage.getItem('logan') || '')
		? JSON.parse(localStorage.getItem('logan')!)
		: '';

	const config = useRuntimeConfig();

	// desperate measure 1
	let stableId = localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID') || '';

	// desperate measure 2
	if (stableId && stableId === '') {
		stableId = window.$statsig!.getStableID();
	}

	let email = null;
	let user_id = null;

	if (logan && logan.isSignedIn) {
		email = logan.email === '' ? null : logan.email;
		user_id = logan.loganId ? logan.loganId : null;
	}

	const userObject = {
		user_email: email,
		user_id: user_id,
		environment: useRuntimeConfig().public.cathodeEnv || '',
		country: useCountryCode().countryCode || '',
		stableId: stableId,
	};

	const cathodeClient = new CathodeEventClient(
		config.public.cathodeBaseUrl,
		userObject,
	);

	console.log('Cathode client initialized with user object:');

	return {
		provide: {
			cathodeClient,
		},
	};
});

declare module 'nuxt/app' {
	interface NuxtApp {
		$cathodeClient: CathodeEventClient;
	}
}
