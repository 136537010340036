import { defineStore } from 'pinia';
export const useCountryCode = defineStore('countryCode', () => {
	const countryCode = ref('');

	const setCountryCode = (newValue: string) => {
		countryCode.value = newValue;
	};
	return {
		countryCode,
		setCountryCode,
	};
});
