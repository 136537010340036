import { validatorFactory } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';
import { useGlobalStore } from '~/stores/global';
import { type Static } from '@sinclair/typebox';
import { useMutation, useQuery } from '@tanstack/vue-query';
import axios from 'axios';
import {
	DeleteResponseSchema,
	LoganMergerResponseSchema,
	LoganResponseSchema,
	OtpSendSchema,
	UpdateInfoSchema,
} from '~/components/logan/types';

// Schema for api call when pressing send otp after entering phone number or email, also being used for send merger otp
const otpSendSchema = OtpSendSchema;
export type OtpSendSchema = Static<typeof otpSendSchema>;
const otpSendValidator = validatorFactory<OtpSendSchema>(otpSendSchema);
//
// Schema for logan response upon sign
const loganResponseSchema = LoganResponseSchema;
export type LoganResponseSchema = Static<typeof loganResponseSchema>;
const loganResponseValidator =
	validatorFactory<LoganResponseSchema>(loganResponseSchema);
//
// Schema for logan response upon merger
const loganMergerResponseSchema = LoganMergerResponseSchema;
export type LoganMergerResponseSchema = Static<
	typeof loganMergerResponseSchema
>;
const loganMergerResponseValidator =
	validatorFactory<LoganMergerResponseSchema>(loganMergerResponseSchema);

//Delete validator
const deleteResponseValidator =
	validatorFactory<typeof DeleteResponseSchema>(DeleteResponseSchema);

// updateinfo validator
const updateInfoSchema = UpdateInfoSchema;
export type UpdateInfoSchema = Static<typeof updateInfoSchema>;
const updateInfoValidator =
	validatorFactory<UpdateInfoSchema>(updateInfoSchema);
export const useSendOtp = () => {
	const { store, storeType } = useGlobalStore();
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			email: string | undefined;
			phone: string | undefined;
			logan_action: string | undefined;
		}) => {
			const api = createApiInstance();
			const response = await api.post('/users/buyer/send_otp/', {
				...body,
				store_id: storeType === 'OCTANE' ? store.id : undefined,
			});
			if (response.status === 200) {
				const body = response.data;
				try {
					return otpSendValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify send otp data');
					console.error('Failed to verify send otp data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Send OTP request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useEnterOtp = () => {
	const { store, storeType } = useGlobalStore();
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			logan_otp: string;
			phone: string | undefined;
			email: string | undefined;
		}) => {
			const api = createApiInstance();
			const response = await api.post('/users/buyer/login_with_otp/', {
				...body,
				store_id: storeType === 'OCTANE' ? store.id : undefined,
			});
			if (response.status === 200) {
				const body = response.data;
				try {
					return loganResponseValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify logan response data');
					console.error('Failed to verify logan response data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Enter OTP request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useSendMergerOtp = () => {
	const { store, storeType } = useGlobalStore();
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			email: string | undefined;
			phone: string | undefined;
		}) => {
			const api = createApiInstance();
			const response = await api.post('/users/buyer/send_otp/', {
				...body,
				store_id: storeType === 'OCTANE' ? store.id : undefined,
			});
			if (response.status === 200) {
				const body = response.data;
				try {
					return otpSendValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify send otp data');
					console.error('Failed to verify send otp data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Send OTP request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useEnterMergerOtp = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			logan_otp: string;
			phone: string | undefined;
			email: string | undefined;
			store_id: number;
		}) => {
			const api = createApiInstance();
			const response = await api.post('/users/buyer/merge_with_otp/', body);
			if (response.status === 200) {
				const body = response.data;
				try {
					return loganMergerResponseValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify logan response data');
					console.error('Failed to verify logan response data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Enter OTP request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useDeleteAccount = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: { logan_id: string; access_token: string }) => {
			const api = createApiInstance();
			const response = await api.post('/customer/delete', body);
			if (response.status === 200) {
				const body = response.data;
				try {
					return deleteResponseValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify delete response data');
					console.error('Failed to verify delete response data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Delete request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};
export const useUpdateUserInfo = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: {
			first_name: string;
			last_name: string;
			gender: string | null;
			date_of_birth: string | null;
			tags: string[];
			logan_action: string;
			deleted: boolean;
		}) => {
			const api = createApiInstance();
			const response = await api.patch('users/buyer/', body);
			if (response.status === 200) {
				const body = response.data;
				try {
					return updateInfoValidator.verify(body);
				} catch (err) {
					const error = new Error('Failed to verify update info data');
					console.error('Failed to verify update info data', err);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: err,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Update info request failed');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

export const useHandleAddress = () => {
	const { data, isPending, isError, error, isSuccess, mutate, mutateAsync } =
		useMutation({
			mutationFn: async (body: any) => {
				const api = createApiInstance();
				let path = '';
				if (body.id) {
					path = `/addresses/buyer/${body.id}/`;
				} else {
					path = '/addresses/buyer/';
				}
				const response = await api.post(path, body.address);
				if (response.status === 201 || response.status === 200) {
					return true;
				}
				const error = new Error('Failed to handle address');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			},
		});
	return { isPending, isError, error, isSuccess, mutate, data, mutateAsync };
};

// FIXME: type me
export const useGetUserInfo = () => {
	const { data, isLoading, suspense, error, refetch } = useQuery({
		staleTime: Infinity,
		queryKey: [],
		queryFn: async () => {
			const api = createApiInstance();
			const response = await api.get('/users/buyer/');
			if (response.status === 200) {
				return response;
			}
			const error = new Error('Failed to get user info');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
		enabled: false,
		retryOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
	return { data, isLoading, suspense, error, refetch };
};

export const useDeleteAddress = () => {
	const { data, isPending, isError, error, isSuccess, mutate } = useMutation({
		mutationFn: async (body: any) => {
			const api = createApiInstance();
			const response = await api.delete(`/addresses/buyer/${body.id}/`);
			if (response.status === 200) {
				return true;
			}
			const error = new Error('Failed to delete address');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { isPending, isError, error, isSuccess, mutate, data };
};

interface RefreshTokenResponse {
	access_token: string;
	refresh_token: string;
}

export const refreshAuthTokens = (refreshToken: string) => {
	return axios.post<RefreshTokenResponse>(
		useRuntimeConfig().public.apiBaseUrl + '/users/auth/refresh/',
		null,
		{
			headers: {
				'logan-token': refreshToken,
			},
		},
	);
};
