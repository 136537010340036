export const logPagePerformance = () => {
	const { $eventClient } = useNuxtApp();
	const percentageToFire = 5;
	const randomPercentage = Math.floor(Math.random() * 100) + 1;
	if (randomPercentage <= percentageToFire) {
		if (typeof window?.performance?.getEntriesByType !== 'function') {
			return;
		}
		const entries = window.performance.getEntriesByType('navigation');
		if (!entries || entries.length < 1) {
			return;
		}

		const navEntry = entries[0]!;
		if (navEntry instanceof PerformanceNavigationTiming) {
			$eventClient.sendEvent('page-load', {
				location: navEntry.name,
				time: navEntry.duration,
			});
			$eventClient.sendEvent('page-dom-interactive', {
				location: navEntry.name,
				time: navEntry.domInteractive,
			});
		}
	}
};
