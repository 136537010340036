import { defineNuxtPlugin, useCookie, useRuntimeConfig } from 'nuxt/app';
import axios from 'axios';

export default defineNuxtPlugin(() => {
	const runtimeConfig = useRuntimeConfig();
	const instance = axios.create({
		baseURL: runtimeConfig.public.apiBaseUrl as string,
	});
	// Request interceptor for API calls
	const userToken = useCookie('user_token');
	instance.interceptors.request.use(
		async (config) => {
			if (userToken) {
				config.headers.Authorization = `Bearer ${userToken}`;
			}
			config.headers['Content-Type'] = 'application/json';
			return config;
		},
		(error) => {
			Promise.reject(error);
		},
	);
	// Response interceptor for API calls
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (error) {
			if (!axios.isAxiosError(error)) {
				console.log('Error is not an Axios error', error);
				return Promise.reject(error);
			} else if (!error.response) {
				console.log('Error has no response', error);
				return Promise.reject(error);
			} else if (!error.config) {
				console.log('Error has no config', error);
				return Promise.reject(error);
			}
			const originalRequest = error.config;
			const retryCount = originalRequest.headers.get('Retry-Count') || 0;
			if (error.response.status === 403 && !retryCount) {
				originalRequest.headers['Retry-Count'] = 1;
				// Refresh token code here
				const access_token = 'new_access_token';
				axios.defaults.headers.common['Authorization'] =
					'Bearer ' + access_token;
				return instance(originalRequest);
			}
			return Promise.reject(error);
		},
	);

	return {
		provide: {
			axios: instance,
		},
	};
});
