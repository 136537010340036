export const PAYMENT_METHODS = Object.freeze({
	COD: 'Cash on Delivery (COD)' as const,
	BANK_DEPOSIT: 'Bank Deposit',
	BANK_DEPOSIT_DISABLED: 'Bank Deposit (Disabled)',
	CREDIT_CARD: 'Credit/Debit Card (Local)',
	CREDIT_CARD_INTL: 'Credit/Debit Card (International)',
});

export const FORM_FIELDS = Object.freeze({
	ADDRESS1: 'address1',
	ADDRESS2: 'address2',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	PROVINCE: 'province',
	COUNTRY: 'country',
	CITY: 'city',
	COMPANY: 'company',
	PHONE: 'phone',
	ZIP: 'zip',
});

export const FORM_FIELD_TYPES = Object.freeze({
	CITY_DROPDOWN: 'city-dropdown',
	TEXT: 'text',
	SELECT: 'select',
});

export const PROMOTION_CODES = Object.freeze({
	SHIP_FREE: 'shipfree',
});

export const BILLING_ADDRESS_OPTIONS = Object.freeze({
	SAME: 'Same as shipping address',
	DIFFERENT: 'Use a different billing address',
});

export const CURRENCIES = Object.freeze({
	usd: {
		symbol: '$',
		code: 'usd',
	},
	cad: {
		symbol: '$',
		code: 'cad',
	},
	eur: {
		symbol: '€',
		code: 'eur',
	},
	gbp: {
		symbol: '£',
		code: 'gbp',
	},
	aed: {
		symbol: 'AED',
		code: 'aed',
	},
	inr: {
		symbol: '₹',
		code: 'inr',
	},
	bdt: {
		symbol: '৳',
		code: 'bdt',
	},
	aud: {
		symbol: '$',
		code: 'aud',
	},
	pkr: {
		symbol: 'PKR',
		code: 'pkr',
	},
});

export type CURRENCY = keyof typeof CURRENCIES;

export const countriesCurrencyMap: { [key: string]: string } = {
	PK: 'pkr',
	US: 'usd',
	AE: 'aed',
	GB: 'gbp',
	CA: 'cad',
	AU: 'aud',
	IN: 'inr',
	AT: 'eur',
	BE: 'eur',
	HR: 'eur',
	CY: 'eur',
	EE: 'eur',
	FI: 'eur',
	FR: 'eur',
	DE: 'eur',
	GR: 'eur',
	IE: 'eur',
	IT: 'eur',
	LV: 'eur',
	LT: 'eur',
	LU: 'eur',
	MT: 'eur',
	NL: 'eur',
	PT: 'eur',
	SK: 'eur',
	SI: 'eur',
	ES: 'eur',
};

// sources of data for laam.pk product cards
export const DataSourceEnum = Object.freeze({
	VALHALLA: 'valhalla',
	DIRECT: 'direct',
	STOREFRONT: 'storefront',
});

export const DataSourceEnumMap = Object.freeze({
	storefront: 'sf',
	valhalla: 'v',
});

/* 
List of features that lead to product views & add to carts
This enum would be used for clicked_from field in firing events to statsig and cathode
clicked_from would be an array of strings e.g user clicks on quick-view via the syte search: 
clicked_from = ["search", "quick_view"]  & data_source = ["syte"]
*/
export const FeatureEnum = Object.freeze({
	BRAND_PAGE_CAROUSEL: 'brand_page_carousel',
	CHATBOT: 'chatbot',
	COLLECTION_LISTING: 'collection_listing',
	FLASH_SALE_CAROUSEL: 'flash_sale_carousel',
	HOMEPAGE_LISTING: 'homepage_listing',
	JUST_FOR_YOU_CAROUSEL: 'just_for_you_carousel',
	MORE_FROM_BRAND_CAROUSEL: 'more_from_brand_carousel',
	MORE_FROM_SAME_CATEGORY_CAROUSEL: 'more_from_same_category_carousel',
	MORE_FROM_THE_COLLECTION_CAROUSEL: 'more_from_the_collection_carousel',
	MORE_WITH_SIMILAR_DELIVERY_CAROUSEL: 'more_with_similar_delivery_carousel',
	MOST_LIKED_CAROUSEL: 'most_liked_carousel',
	MOST_LIKED_DISCOUNTED_CAROUSEL: 'most_liked_discounted_carousel',
	NEXT_PREV: 'next_prev',
	QUICK_VIEW: 'quick_view',
	SEARCH: 'search',
	SEARCH_BY_IMAGE: 'search_by_image',
	SIMILAR_PRODUCTS_CAROUSEL: 'similar_products_carousel',
	WISHLIST: 'wishlist',
	WISHLIST_CAROUSEL: 'wishlist_carousel',
	YOU_MAY_ALSO_LIKE_CAROUSEL: 'you_may_also_like_carousel',
});

/*
Mapping enum used for using short forms in query params for better visibility. Query params being used:
feat: FeatureEnumMap[FeatureEnum.KEY]
ver: valhalla version returned from products API (node-api)
*/
export const FeatureEnumMap = Object.freeze({
	brand_page_carousel: 'bpc',
	collection_listing: 'cl',
	flash_sale_carousel: 'fsc',
	homepage_listing: 'hl',
	just_for_you_carousel: 'jfc',
	more_from_same_category_carousel: 'mfscc',
	more_from_brand_carousel: 'mfbc',
	most_liked_discounted_carousel: 'moldc',
	most_liked_carousel: 'mlc',
	more_from_the_collection_carousel: 'mftcc',
	more_with_similar_delivery_carousel: 'mwsdc',
	np: 'next_prev',
	quick_view: 'qv',
	search: 's',
	search_by_image: 'sbi',
	similar_products_carousel: 'spc',
	wishlist: 'wl',
	wishlist_carousel: 'wl_c',
	you_may_also_like_carousel: 'ymalc',
});

export const REWARD_TYPES = Object.freeze({
	EARNED: 'EARNED',
	TO_BE_EARNED: 'TO_BE_EARNED',
	REDEEMED: 'REDEEMED',
	EXPIRED: 'EXPIRED',
	REFUNDED: 'REFUNDED',
	RETURNED_TO_LAAM: 'RETURNED_TO_LAAM',
	CANCELLED: 'CANCELLED',
});

export const PROTECTED_ROUTES = ['/rewards', '/wishlist', '/orders'];

export const USTAXEDSTATES = ['GA', 'IL', 'MD', 'MI', 'OH', 'VA', 'WA'];

export const PDPDynamicTitleEnum = [
	'color-type',
	'node_l4',
	'node_l1',
	'brand',
	'vendor',
	'number-of-pieces',
	'season',
];

export const PDPDynamicImageTitleEnum = [
	'color-type',
	'node_l4',
	'node_l1',
	'brand',
	'vendor',
];

export const SEARCH_NODES = Object.freeze([
	{
		name: 'All',
		id: null,
	},
	{
		name: 'Men',
		id: 21,
	},
	{
		name: 'Women',
		id: 1,
	},
	{
		name: 'Kids',
		id: 10000,
	},
]);

export const DuplicateOrderMessage = Object.freeze({
	DUPLICATE_ORDER: 'duplicateOrder',
});
