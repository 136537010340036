import { cva, type VariantProps } from 'class-variance-authority';
export const iconButtonVariants = cva(
	/*tw*/ 'icon-button inline-flex items-center justify-center ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-0 focus-visible:ring-opacity-15 disabled:pointer-events-none',
	{
		variants: {
			variant: {
				primary:
					/*tw*/ 'bg-gray-700 text-white hover:bg-gray-900 focus:outline disabled:text-gray-400 disabled:bg-gray-100',
				secondary:
					/*tw*/ 'bg-white border-gray-300 border text-black hover:bg-gray-50 disabled:border-gray-200 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400',
				tertiary:
					/*tw*/ 'bg-white text-black hover:bg-gray-50 focus-visible:ring-0 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400',
				icon: /*tw*/ ' text-gray-700 [&_svg]:fill-gray-700 hover:bg-gray-50 focus-visible:ring-0 disabled:text-gray-400 disabled:[&_svg]:fill-gray-400',
				addProduct:
					/*tw*/ ' text-bg-blue-600 [&_svg]:bg-blue-600 hover:bg-blue-600 focus-visible:ring-0 disabled:text-blue-400 disabled:[&_svg]:fill-blue-400',
			},
			size: {
				xs: /*tw*/ 'p-md [&_svg]:h-3xl [&_svg]:w-3xl',
				sm: /*tw*/ ' p-md [&_svg]:h-5xl [&_svg]:w-5xl',
				md: /*tw*/ ' p-lg [&_svg]:h-5xl [&_svg]:w-5xl',
				lg: /*tw*/ ' p-xl [&_svg]:h-5xl [&_svg]:w-5xl',
				xl: /*tw*/ ' p-2xl [&_svg]:h-5xl [&_svg]:w-5xl',
				'2xl': /*tw*/ ' p-3xl [&_svg]:h-7xl [&_svg]:w-7xl',
				'nav-button': /*tw*/ ' p-md [&_svg]:h-7xl [&_svg]:w-7xl',
			},
			rounded: {
				default: 'rounded-medium',
				full: 'rounded-full',
			},
		},
		defaultVariants: {
			variant: 'primary',
			size: 'xs',
			rounded: 'default',
		},
	},
);

export type IconButtonVariants = VariantProps<typeof iconButtonVariants>;
export { default as IconButton } from './IconButton.vue';
