import { DynamicConfig } from 'statsig-js';

class _StatsigClient {
	private getInstance = () => {
		if (!window.$statsig) {
			throw new Error('Statsig SDK not initialized');
		}
		return window.$statsig;
	};

	checkGate(flightName: string): boolean {
		const Statsig = this.getInstance();
		return Statsig.checkGate(flightName);
	}

	getDynamicConfig(configName: string): DynamicConfig {
		const Statsig = this.getInstance();
		return Statsig.getConfig(configName);
	}

	getStableID(): string | null {
		const Statsig = this.getInstance();
		return Statsig.getStableID();
	}

	getExperiment(experimentName: string) {
		const Statsig = this.getInstance();
		return Statsig.getExperiment(experimentName);
	}

	checkExperiment<T>(
		experimentName: string,
		placement: string,
		defaultVal: T,
	): T {
		const Statsig = this.getInstance();
		const experiment = Statsig.getExperiment(experimentName);
		return experiment ? experiment.get(placement, defaultVal) : defaultVal;
	}
}

export const StatsigSingleton = new _StatsigClient();
