// @ts-nocheck
import type { IAdapter, InitArguments } from '../event-client';

const subscribedEvents: Array<EventTypes> = [
	'product-view',
	'page-view',
	'add-to-cart',
	'purchase',
	'proceed-to-checkout',
];

const loadOmnisendScript = async (arg: InitArguments['omnisend']) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.src = 'https://omnisnippet1.com/inshop/launcher-v2.js';
	window.omnisend = window.omnisend || [];
	window.omnisend.push(['accountID', arg]);
	window.omnisend.push(['track', '$pageViewed']);
	document.body.appendChild(script);

	return new Promise((resolve, reject) => {
		script.onload = () => {
			resolve(window.omnisend);
		};
		script.onerror = (err) => {
			console.error('Omnisend script failed to load');
			reject(err);
		};
	});
};

class Transformer {
	static transformPageView(data: EventPayloads['page-view']) {
		window.omnisend.push([
			'track',
			'page view',
			{
				origin: 'api',
				properties: data,
			},
		]);
	}
	static transformProductView(data: EventPayloads['product-view']) {
		const payload = {
			id: data.id.toString(),
			source: data.source,
			currency: data.currency,
			price: data.price,
			imageUrl: data.imageUrl,
			title: data.title,
			url: data.url,
			status: data.status,
		};
		window.omnisend.push([
			'track',
			'product view',
			{
				origin: 'api',
				properties: payload,
			},
		]);
		window.omnisend.push([
			'track',
			'viewed product',
			{
				eventVersion: 'v4',
				origin: 'api',
				properties: payload,
			},
		]);
	}
	static transformAddToCartEvent(data: EventPayloads['add-to-cart']) {
		window.omnisend.push([
			'track',
			'added product to cart',
			{
				origin: 'api',
				properties: data,
			},
		]);
	}
	static transformProceedToCheckout(
		data: EventPayloads['proceed-to-checkout'],
	) {
		window.omnisend.push([
			'track',
			'started checkout',
			{
				origin: 'api',
				properties: data,
			},
		]);
	}
	static transformPurchaseEvent(data: EventPayloads['purchase']) {
		let orderNum = data.orderNumber;
		if (orderNum) {
			orderNum = orderNum.replace('LAAM-', '');
			data['orderNumber'] = orderNum;
		}
		window.omnisend.push([
			'track',
			'purchase',
			{
				origin: 'api',
				properties: data,
			},
		]);
		const data_ = data.lineItems;
		data_.map((items) => {
			window.omnisend.push([
				'track',
				'ordered a product',
				{
					origin: 'api',
					properties: items,
				},
			]);
		});
	}
}

const TRANSFORMER_MAP = {
	'product-view': Transformer.transformProductView,
	'page-view': Transformer.transformPageView,
	'add-to-cart': Transformer.transformAddToCartEvent,
	'proceed-to-checkout': Transformer.transformProceedToCheckout,
	purchase: Transformer.transformPurchaseEvent,
};

export class OmnisendAdapter implements IAdapter {
	async init(arg: InitArguments['omnisend']): Promise<void> {
		await loadOmnisendScript(arg.brandId);
		const logan = localStorage.getItem('logan');
		if (isJSON(logan)) {
			const payload = JSON.parse(logan);
			window.omnisend.identifyContact({
				email: payload?.email,
				phone: payload?.phoneNumber,
			});
		}
	}
	sendEvent<T extends EventTypes>(
		eventName: T,
		eventData: EventPayloads[T],
	): void {
		if (!subscribedEvents.includes(eventName)) {
			return;
		}
		if (eventName in TRANSFORMER_MAP) {
			TRANSFORMER_MAP[eventName](eventData);
		} else {
			window.omnisend.push(['track', eventName, eventData]);
		}
	}
	login(data: LoginPayload): void {
		window.omnisend.identifyContact({
			email: data?.email,
			phone: data?.phone,
		});
	}
	logout(): void {
		// No operation
	}
}
