import { PAYMENT_METHODS } from '~/utils/enums.ts';
import { loadStripe } from '@stripe/stripe-js/pure';

const isJSON = (value: string) => {
	try {
		JSON.parse(value);
		return true;
	} catch {
		return false;
	}
};

const isObject = (value: string | object) => {
	return typeof value === 'object';
};

const dateFormatter = (getDate: string, showYear: boolean = false) => {
	const date = new Date(getDate);
	const dateParse = date.toDateString().split(' ');
	return !showYear
		? dateParse[2] + ' ' + dateParse[1]
		: dateParse[2] + ' ' + dateParse[1] + ' ' + dateParse[3];
};

const keyExistsInStorage = (key: string) => {
	if (localStorage.getItem(key) === null) {
		return false;
	}
	return true;
};

interface Window {
	googleScriptLoaded?: boolean;
	xpayScriptLoaded?: boolean;
	stripeScriptLoaded?: boolean;
}
const loadGoogleMapsScript = async () => {
	const isScriptLoaded =
		sessionStorage.getItem('googleScriptLoaded') === 'true' ? true : false;

	if (isScriptLoaded) {
		return;
	}

	const script = document.createElement('script');
	script.src = `https://maps.googleapis.com/maps/api/js?key=${useRuntimeConfig().public.gmapsPlacesKey}&libraries=places`;
	script.async = true;
	script.defer = true;

	await new Promise((resolve) => {
		script.onload = () => {
			sessionStorage.setItem('googleScriptLoaded', 'true');
		};
		resolve(1);
	});

	document.body.appendChild(script);
};

const shortenHash = (hash: string) => {
	if (hash.length > 20) {
		return hash.slice(0, 10) + hash.slice(-10);
	}
	return hash;
};

const loadXpayScript = async () => {
	if ((window as Window).xpayScriptLoaded) {
		return;
	}
	await new Promise<void>((resolve, reject) => {
		const script = document.createElement('script');
		script.src = 'https://js.xstak.com/v3/xpay.js'; //prod
		// script.src = 'https://js.xstak.com/xpay-stage.js'; //dev

		script.async = true;
		script.defer = true;

		script.onload = () => {
			(window as Window).xpayScriptLoaded = true;
			resolve();
		};

		script.onerror = () => {
			reject(new Error('Failed to load Xpay script'));
		};

		document.body.appendChild(script);
	});
};

const loadStripeScript = () => {
	const config = useRuntimeConfig();
	return loadStripe(config.public.stripePublishableKey);
};

const validationScroll = (id: string) => {
	if (id === 'checkout-contact-information') {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	} else document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

const validatePhoneNumber = (
	phoneNumber: string,
	phoneRegexPattern: string,
) => {
	if (phoneRegexPattern && phoneRegexPattern.trim() !== '') {
		const regex = new RegExp(phoneRegexPattern);
		if (!regex.test(phoneNumber)) {
			return false;
		}
	} else {
		if (!/^\d+$/.test(phoneNumber)) {
			return false;
		}
	}
	return true;
};

const validateZipCode = (zip: string) => {
	if (!/^\d{5}$/.test(zip)) {
		return false;
	}
	return true;
};

const paymentConfigParser = (method: string) => {
	switch (method) {
		case 'cod':
			return PAYMENT_METHODS.COD;
		case 'bank':
			return PAYMENT_METHODS.BANK_DEPOSIT;
		case 'card':
			return 'Credit/Debit Card';
		default:
			return;
	}
};

const convertToNormalCase = (str: string) => {
	return str.replace(/_/g, ' ').replace(/\b[A-Z]+\b/g, (word) => {
		return word.charAt(0) + word.slice(1).toLowerCase();
	});
};

const validateEmail = (email: string) => {
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
		email,
	);

	return emailRegex;
};

export {
	isJSON,
	isObject,
	dateFormatter,
	keyExistsInStorage,
	loadGoogleMapsScript,
	loadXpayScript,
	loadStripeScript,
	shortenHash,
	validationScroll,
	validatePhoneNumber,
	convertToNormalCase,
	paymentConfigParser,
	validateZipCode,
	validateEmail,
};
