<script setup lang="ts"></script>
<template>
	<svg
		width="768"
		height="736"
		viewBox="0 0 768 736"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_21_102638"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="-32"
			width="768"
			height="768"
		>
			<rect
				width="768"
				height="768"
				transform="translate(0 -32)"
				fill="url(#paint0_radial_21_102638)"
			/>
		</mask>
		<g mask="url(#mask0_21_102638)">
			<g clip-path="url(#clip0_21_102638)">
				<g clip-path="url(#clip1_21_102638)">
					<line x1="0.5" y1="-32" x2="0.5" y2="736" stroke="#E5E5E5" />
					<line x1="48.5" y1="-32" x2="48.5" y2="736" stroke="#E5E5E5" />
					<line x1="96.5" y1="-32" x2="96.5" y2="736" stroke="#E5E5E5" />
					<line x1="144.5" y1="-32" x2="144.5" y2="736" stroke="#E5E5E5" />
					<line x1="192.5" y1="-32" x2="192.5" y2="736" stroke="#E5E5E5" />
					<line x1="240.5" y1="-32" x2="240.5" y2="736" stroke="#E5E5E5" />
					<line x1="288.5" y1="-32" x2="288.5" y2="736" stroke="#E5E5E5" />
					<line x1="336.5" y1="-32" x2="336.5" y2="736" stroke="#E5E5E5" />
					<line x1="384.5" y1="-32" x2="384.5" y2="736" stroke="#E5E5E5" />
					<line x1="432.5" y1="-32" x2="432.5" y2="736" stroke="#E5E5E5" />
					<line x1="480.5" y1="-32" x2="480.5" y2="736" stroke="#E5E5E5" />
					<line x1="528.5" y1="-32" x2="528.5" y2="736" stroke="#E5E5E5" />
					<line x1="576.5" y1="-32" x2="576.5" y2="736" stroke="#E5E5E5" />
					<line x1="624.5" y1="-32" x2="624.5" y2="736" stroke="#E5E5E5" />
					<line x1="672.5" y1="-32" x2="672.5" y2="736" stroke="#E5E5E5" />
					<line x1="720.5" y1="-32" x2="720.5" y2="736" stroke="#E5E5E5" />
				</g>
				<rect x="0.5" y="-31.5" width="767" height="767" stroke="#E5E5E5" />
				<g clip-path="url(#clip2_21_102638)">
					<line y1="15.5" x2="768" y2="15.5" stroke="#E5E5E5" />
					<line y1="63.5" x2="768" y2="63.5" stroke="#E5E5E5" />
					<line y1="111.5" x2="768" y2="111.5" stroke="#E5E5E5" />
					<line y1="159.5" x2="768" y2="159.5" stroke="#E5E5E5" />
					<line y1="207.5" x2="768" y2="207.5" stroke="#E5E5E5" />
					<line y1="255.5" x2="768" y2="255.5" stroke="#E5E5E5" />
					<line y1="303.5" x2="768" y2="303.5" stroke="#E5E5E5" />
					<line y1="351.5" x2="768" y2="351.5" stroke="#E5E5E5" />
					<line y1="399.5" x2="768" y2="399.5" stroke="#E5E5E5" />
					<line y1="447.5" x2="768" y2="447.5" stroke="#E5E5E5" />
					<line y1="495.5" x2="768" y2="495.5" stroke="#E5E5E5" />
					<line y1="543.5" x2="768" y2="543.5" stroke="#E5E5E5" />
					<line y1="591.5" x2="768" y2="591.5" stroke="#E5E5E5" />
					<line y1="639.5" x2="768" y2="639.5" stroke="#E5E5E5" />
					<line y1="687.5" x2="768" y2="687.5" stroke="#E5E5E5" />
					<line y1="735.5" x2="768" y2="735.5" stroke="#E5E5E5" />
				</g>
				<rect x="0.5" y="-31.5" width="767" height="767" stroke="#E5E5E5" />
			</g>
		</g>
		<defs>
			<radialGradient
				id="paint0_radial_21_102638"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(384 384) rotate(90) scale(384 384)"
			>
				<stop />
				<stop offset="1" stop-opacity="0" />
			</radialGradient>
			<clipPath id="clip0_21_102638">
				<rect
					width="768"
					height="768"
					fill="white"
					transform="translate(0 -32)"
				/>
			</clipPath>
			<clipPath id="clip1_21_102638">
				<rect y="-32" width="768" height="768" fill="white" />
			</clipPath>
			<clipPath id="clip2_21_102638">
				<rect y="-32" width="768" height="768" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
