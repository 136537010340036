import { useRuntimeConfig } from 'nuxt/app';
export default defineNuxtPlugin(() => {
	const config = useRuntimeConfig();
	if (config.public.env === 'production') {
		// Object.keys(console).forEach(() => {
		//@ts-ignore
		// console[key] = () => {};
		// });
	}
});
