<script setup lang="ts">
import { Button, IconButton } from '@laam/ui/base';
import ErrorPageBackground from './icons/ErrorPageBackground.vue';
import { PhArrowLeft, PhMagnifyingGlass } from '@phosphor-icons/vue';
import type { NuxtError } from 'nuxt/app';
const router = useRouter();

const props = defineProps({
	// eslint-disable-next-line vue/require-default-prop
	error: Object as () => NuxtError,
});
</script>

<template>
	<div
		class="flex flex-col items-center relative justify-center lg:justify-start"
	>
		<ErrorPageBackground class="absolute" />
		<div
			class="flex flex-col items-center justify-center lg:gap-10xl absolute z-20 lg:top-[400px] top-11xl gap-8xl p-3xl"
		>
			<div class="flex flex-col items-center justify-center lg:gap-7xl gap-3xl">
				<NuxtLink to="/search">
					<IconButton variant="secondary" size="lg">
						<PhMagnifyingGlass />
					</IconButton>
				</NuxtLink>
				<p class="font-semibold lg:text-6xl text-gray-900 text-center text-4xl">
					<template v-if="props.error?.statusCode === 400">
						{{ props.error.message }}
					</template>
					<template v-else-if="props.error?.statusCode === 500">
						{{ 'Server Error' }}
					</template>
					<template v-else> Page not found </template>
				</p>
				<p class="text-gray-600 text-center text-xl font-normal max-w-[1216px]">
					<template
						v-if="
							props.error?.statusCode === 400 || props.error?.statusCode === 500
						"
					>
						{{ props.error.message }}
					</template>
					<template v-else>
						The page you are looking for doesn't exist.
					</template>
					<br />
					Here are some helpful links:
				</p>
			</div>
			<div class="flex gap-xl lg:flex-row flex-col w-full">
				<Button
					size="2xl"
					variant="secondary"
					class="w-full hidden lg:flex"
					@click="router.go(-1)"
				>
					<template #leftIcon>
						<PhArrowLeft />
					</template>
					Go back
				</Button>
				<Button
					size="xl"
					variant="secondary"
					class="w-full lg:hidden"
					@click="router.go(-1)"
				>
					<template #leftIcon>
						<PhArrowLeft />
					</template>
					Go back
				</Button>
				<NuxtLink to="/" class="w-full">
					<Button size="2xl" class="w-full hidden lg:block">
						Take me home
					</Button>
					<Button size="xl" class="w-full lg:hidden"> Take me home </Button>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>
